.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiMenu-root .MuiPaper-elevation .MuiMenuItem-root em {
  font-style: normal;
}
.MuiPaper-elevation {
  border-radius: 6px;
  margin-top: 10px;
  /* width: fit-content !important; */
}
.MuiPaper-elevation ul {
  padding: 0 12px;
  max-height: 200px;
}
.MuiPaper-elevation ul li {
  padding: 12px 14px !important;
  color: #686465;
  font-size: 18px !important;
  font-weight: 400;
  line-height: 24px !important;
  text-transform: capitalize;
}
.MuiPaper-elevation ul li.Mui-selected {
  background-color: rgb(54 72 102 / 0%) !important;
}

.filter-box:not(:last-child) {
  border-bottom: 1px solid #cdcbcc !important;
  text-transform: capitalize;
}

.language-select {
  padding: 4px 14px !important;
  color: #686465 !important;
}

::placeholder {
  color: #8e8a8b !important;
  opacity: 1 !important;
}
::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #8e8a8b !important;
  opacity: 1 !important;
}

.resend-otp {
  margin-top: 24px;
  margin-bottom: 10px;
  justify-content: center !important;
  align-items: baseline !important;
  gap: 15px;
  font-size: 18px !important;
}
.contact-sub-link {
  color: #2974ef;
}
.website-display {
  text-decoration: none;
  word-break: break-all;
}

.signup-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.edit-customer-box {
  border-radius: 16px;
  background-color: #f5f7fa;
  height: 100%;
}
.disable-option {
  opacity: 0.5;
  pointer-events: none;
}
.enalble-option {
  opacity: 1;
  pointer-events: auto;
}

.table-body {
  background-color: #f5f7fa;
  padding: 24px;
  border-radius: 16px;
}
#filterMenu .MuiPaper-elevation ul {
  padding: 14px;
  overflow: auto;
}

#filterMenu .MuiPaper-elevation ul::-webkit-scrollbar {
  width: 5px;
}

#filterMenu .MuiPaper-elevation ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#filterMenu .MuiPaper-elevation ul::-webkit-scrollbar-thumb {
  background: #888;
}
#menu-entryType ul li:not(:last-child) {
  border-bottom: 1px solid #cdcbcc;
}
#menu-entryType .MuiPaper-elevation {
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1) !important;
}
#menu-entryType ul li {
  font-size: 16px;
}
.download-menu .MuiPaper-root {
  border-radius: 6px !important;
}

.sort-icon {
  display: flex;
  align-items: center;
  gap: 15px;
}
.download-menu .MuiPaper-elevation ul {
  padding: 0 12px;
}
.download-menu .MuiPaper-elevation ul li {
  padding: 12px;
  border-bottom: 1px solid #cdcbcc;
  min-width: 98px;
}
.download-menu .MuiPaper-elevation ul li:last-child {
  border-bottom: 0;
}
#menu-entryType ul li:not(:last-child) {
  border-bottom: 1px solid #cdcbcc;
}
#menu-entryType .MuiPaper-elevation {
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1) !important;
}
#menu-entryType ul li {
  font-size: 16px;
}
.receve-otp {
  color: #686465;
}
.header-dropdown {
  padding: 7px !important;
}
.css-1knys0k-MuiButtonBase-root-MuiMenuItem-root {
  padding: 4px 14px !important;
}
.dropdown-icon {
  cursor: pointer;
  position: absolute;
  left: 14px;
}
.iframe-payment {
  border-radius: 20px !important;
}
.textbox-line {
  text-decoration: underline;
}
.terms-condition-section {
  cursor: pointer !important;
  color: #fd337e !important;
  text-decoration: underline !important;
  margin-left: 5px !important;
}
.email-link-box {
  color: #2974ef !important;
  text-decoration: underline !important;
  margin-left: 5px !important;
}
.image-box {
  fill: '#8E8A8B';
}

.date-picker {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

/* filter button css */
.filter-button {
  background-color: #ffffff !important;
  box-shadow: none !important;
  border: 1px solid #cdcbcc !important;
  border-radius: 6px !important;
  color: #686465 !important;
  width: 100%;
  font-size: 18px !important;
  font-weight: 400 !important;
  max-width: 200px !important;
  display: flex !important;
  justify-content: space-between !important;
  text-transform: capitalize !important;
  padding: 12px 12px !important;
  line-height: 1.5 !important;
}

.userActivity-container {
  background-color: #f5f7fa;
  border-radius: 16px;
  padding: 24px;
  min-height: calc(100vh - 260vh) !important;
}

.profile-box {
  background-color: #ffffff;
  box-shadow: none;
  min-width: 100px;
  min-height: 100px;
  padding: 0;
  width: 42px;
  height: 42px;
  gap: 12px;
  object-fit: cover;
  border-radius: 50%;
  justify-self: center;
  justify-content: center;
  align-items: center;
  display: grid;
  position: relative;
}
.blur-image {
  background-color: bl;
}
.profile-label {
  color: #8e8a8b;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 30px;
  padding-bottom: 8px;
}
.image-label {
  cursor: pointer;
  padding-top: 2px;
}

.image-input {
  display: none;
}
.image {
  display: block;
}

.profile-image {
  width: 95px;
  height: 95px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 37%;
}
.filter-image {
  filter: blur(2px);
  width: 95px;
  height: 95px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  border-radius: 50%;
}

.edit-icon-click {
  position: absolute;
  place-self: center;
  cursor: pointer;
  /* pointer-events: none; */
}

.recent-table {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #686465 !important;
  text-decoration: underline;
  white-space: 'nowrap' !important;
}
.recent-table-tr {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #686465 !important;
  white-space: 'nowrap' !important;
}

.disabled-datepicker {
  background-color: #eee !important;
  color: #999 !important;
}

.edit-button-dashboard {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #686465 !important;
  width: 1% !important;
  white-space: nowrap !important;
}

.edit-plan-label {
  color: #686465 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
}

/* document modal*/

.modal-button {
  position: absolute !important;
  top: -7px !important;
  right: 1px !important;
  width: 100% !important;
  max-width: 42px !important;
}

.invoice-box {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
}

.invoice-paper {
  width: 100%;
  height: 70vh;
}
.modal-paper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-box {
  max-width: 100%;
  max-height: 100%;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  position: relative;
  padding: 40px;
  background-color: white;
  border: 1px solid white;
  border-radius: 15px;
  width: 60vw;
  height: 70vh;
  outline: none;

  @media (max-width: 768px) {
    width: 500px;
    height: 500px;
  }

  @media (max-width: 480px) {
    width: 400px;
    height: 400px;
  }
}

.iframe-doc {
  min-height: 100%;
  min-width: 100%;
  height: 700px;
  width: 700px;
  border-radius: 15px;
}

.subtitle-otp {
  margin-bottom: 13px !important;
  font-size: 17px !important;
  color: #686465;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}
.contact-link {
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-left: 5px !important;
  cursor: pointer;
  text-align: center;
  display: block;
  color: #686465;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(128, 128, 128, 0.45);
  border-radius: 5px;
}

@media (min-width: 320px) and (max-width: 568px) {
  .userActivity-container {
    padding: 15px;
  }
}

/* media query */
@media (min-width: 375px) and (max-width: 667px) {
  .filter-button {
    min-width: 245px !important;
    height: 48px !important;
  }
}

@media (min-width: 360px) and (max-width: 740px) {
  .filter-button {
    min-width: 245px !important;
    height: 48px !important;
  }
}
@media (min-width: 320px) and (max-width: 568px) {
  .filter-button {
    min-width: 250px !important;
    height: 48px !important;
  }
}

@media (min-width: 375px) and (max-width: 667px) {
  .date-picker {
    display: grid;
    margin-top: 15px;
    gap: 5px;
  }
}

@media (min-width: 360px) and (max-width: 667px) {
  .date-picker {
    display: grid;
    margin-top: 15px;
    gap: 5px;
  }
}
@media (min-width: 320px) and (max-width: 568px) {
  .date-picker {
    display: grid;
    margin-top: 15px;
    gap: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .date-picker {
    margin-top: 15px;
    justify-content: space-between;
  }
}
@media (min-width: 280px) and (max-width: 653px) {
  .date-picker {
    display: grid;
    margin-top: 15px;
  }
}

.edit-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.edit-content-header {
  text-transform: capitalize;
  color: #242323;
}

.pagination-block {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.price-box {
  margin-left: 5px;
}

.day-box {
  margin-left: 5px;
  font-size: 25px;
  font-weight: 400;
}

@media (max-width: 575px) {
  .day-box {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .day-box {
    font-size: 18px;
  }
}

.Plan-card-wrapper {
  display: grid;
  grid-auto-columns: unset;
  grid-auto-flow: unset;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  overflow: auto;
}

@media (max-width: 1365px) {
  .Plan-card-wrapper {
    grid-auto-columns: unset;
    grid-auto-flow: unset;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1023px) {
  .Plan-card-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 575px) {
  .Plan-card-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
